import React from "react";
import { ReactComponent as Logo } from "./assets/logo.svg";

function App() {
  return (
    <div className="wrapper">
      <Logo />

      <div className="mail-wrapper">
        <a href="mailto:info@syngineric.com">info@syngineric.com</a>
      </div>
    </div>
  );
}

export default App;
